<template>
  <div class="l-form  sp-pb-4">

    <d-modal v-if="dialog" @close="dialog = false;">
      <d-modal-header>
        <d-modal-title>{{ $t("on_boarding.required_documents.file_examples") }}</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <div>
          <div v-for="(item, index) in slides" :key="index" :class="{ 'sp-mt-4': index > 0 }">
            <h5>{{ $t(item.title) }}:</h5>
            <img :src="getImage(item.image)" style="max-width:320px;" />
            <p v-if="item.description !== ''">
              {{ item.description }}
            </p>
          </div>
        </div>
      </d-modal-body>
    </d-modal>


    <div class="l-form__item h5  sp-mb-0">{{ $t("on_boarding.required_documents.identification_file") }}</div>

    <div class="l-form__item">
      <file-upload
        v-if="docID"
        :provider-id="providerId"
        :label="$t('form_fields.company_id_card')"
        formName="idUploadForm"
        fileType="ID"
        @onUploaded="onFileUploaded"
        @onShowExample="getExamples"
      ></file-upload>
    </div>

    <div class="l-form__item"  v-if="docCOC">
      <hr />
    </div>

    <div class="l-form__item">
      <file-upload
        v-if="docCOC"
        :provider-id="providerId"
        :label="$t('form_fields.company_incorporation')"
        formName="registrationUploadForm"
        fileType="ChamberOfCommerce"
        @onUploaded="onFileUploaded"
        @onShowExample="getExamples"
      ></file-upload>
    </div>

    <div class="l-form__item"  v-if="docAOA">
      <hr />
    </div>

    <div class="l-form__item">
      <file-upload
        v-if="docAOA"
        :provider-id="providerId"
        :label="$t('form_fields.company_association')"
        formName="articleUploadForm"
        fileType="ArticlesOfAssociation"
        @onUploaded="onFileUploaded"
        @onShowExample="getExamples"
      ></file-upload>
    </div>

    <div class="l-form__item">
      <hr />
    </div>

    <div v-if="hasError('others')" class="l-form__item">
      <d-form-invalid-feedback  :force-show="true">
        <span v-html="getError('others')"></span>
      </d-form-invalid-feedback>
    </div>

    <div class="l-form__item">
      <div v-if="!allFilesUploaded" class="sp-mb-2">
        <d-alert theme="light" show>
          <i class="material-icons u-color-secondary--800  sp-mr-1" style="font-size: 16px;">help</i>
          {{ $t("on_boarding.required_documents.upload_all_files_to_continue") }}
        </d-alert>
      </div>
      <div class="u-divide">

        <router-link v-if="!firstTime" :to="{name: 'onBoarding.initial', params: {providerId: providerId}}" class="btn  btn-light">
          {{ $t('form_fields.to_overview') }}
        </router-link>
        <div v-else></div>

        <d-button :disabled="!allFilesUploaded" @click="submit">
          {{ $t('form_fields.save_continue') }}
        </d-button>
      </div>

    </div>

  </div>
</template>

<script>


import {mapFileFields, mapProviderFields, mapRegistrationFields} from "~/store";
import {rules, documentExamples, legalEntity as legalEntityMapping} from "~/onBoardingSettings"
import FileUpload from "@/components/onBoarding/FileUpload";

export default {
  name: "RequiredDocuments",
  components: {FileUpload},

  props: {
    providerId: {
      type: String,
      required: true,
    }
  },


  data() {
    return {
      n: Number,
      valid: false,
      cardIdRules: rules.file.rules,
      allFilesUploaded: false,
      slides: [],
      errors: [],
      dialog: false

    }
  },

  computed: {
    ...mapProviderFields([
      'formData.locale',
      'formData.country',
      'formData.businessType',
      'formData.documents',
      'formData',
    ]),
    ...mapFileFields([
      'ID',
      'ChamberOfCommerce',
      'ArticlesOfAssociation',
      'docID',
      'docCOC',
      'docAOA',
      'documentsToValidate',
    ]),
    ...mapRegistrationFields([
      'firstTime',
    ]),
  },

  mounted() {
    this.setRequiredDocuments()
  },

  methods: {

    hasError: function (field) {
      return this.errors.find(error => error.field === field);
    },

    getError: function (field) {
      return this.errors.find(error => error.field === field).messages.join('<br/>');
    },

    setRequiredDocuments() {

      const legalEntityObject = legalEntityMapping[this.country].find((object) => {
        return object.legalEntity === this.formData.legalEntity;
      });

      if(!legalEntityObject) {
        this.errors.push({
          field: 'others',
          messages: [`No corresponding legal entity found within the given country for legal entity: "${this.formData.legalEntity}"`]
        })
        return;
      }

      this.documentsToValidate = legalEntityObject.documents
      legalEntityObject.documents.forEach( item => {
        if(item === 1) this.docID = true;
        if(item === 2 && this.cocCheck()) this.docCOC = true;
        if(item === 3) this.docAOA = true;
      })
    },

    /*
    * Check if an COC documents has already been uploaded in the ROOSH dashboard
    * If so the backend takes care of uploading the COC document when registering to HiPay
    * */
    cocCheck() {
      let uploadedNeeded = true
      if (this.documents.find(item => item.status === null && item.type === 'ChamberOfCommerce')) uploadedNeeded = false;
      return uploadedNeeded
    },

    getImage: function (img) {
      let country = this.locale.substr(3,5);
      return require("@/assets/img/onBoarding/" + country + "/" + img);
    },

    getExamples(evt) {
      this.dialog = true;

      let country = this.locale.substr(3,5);
      this.slides = (documentExamples[country] !== undefined) ? documentExamples[country][evt.id] : []
    },

    onFileUploaded(evt) {
      this[evt.type] = true

      // Todo: Must be based on the files that need to be uploaded by legal identity
      if ((this.ID != null || !this.docID) && (this.ChamberOfCommerce != null || !this.docCOC) && (this.ArticlesOfAssociation != null || !this.docAOA)) {
        this.allFilesUploaded = true
      }
    },

    submit() {
      this.$router.push({name: 'onBoarding.step3', params: {providerId: this.providerId}})
    }

  }
}
</script>

