<template>

    <div class="l-form" v-if="!isUploaded">

      <div class="l-form__item">
        <div class="h6">
          <d-button outline theme="secondary" size="sm" @click="showExample" style="float: right; margin: -6px 0 5px 10px;">{{ $t('on_boarding.file_upload.show_file_examples') }}</d-button>
          {{ label }}
        </div>
        <div class="custom-file  form__file-uploader">
          <input type="file" class="custom-file-input" id="customFile"
                 @change="previewFile"
                 accept="image/png, image/jpeg, image/gif, application/pdf">
          <label class="custom-file-label" for="customFile">
            <span v-html="fileLabel"></span>
          </label>
        </div>

        <d-form-invalid-feedback v-if="hasError('fileToUpload')" :force-show="true">
          <span v-html="getError('fileToUpload')"></span>
        </d-form-invalid-feedback>
      </div>

      <div class="l-form__item" v-if="getId === 1">
        <div class="h6">
          {{ $t('form_fields.company_id_card_back') }}
        </div>
        <div class="custom-file  form__file-uploader">
          <input type="file" class="custom-file-input" id="customFile2"
                 @change="previewFile2"
                 accept="image/png, image/jpeg, image/gif, application/pdf">
          <label class="custom-file-label" for="customFile2">
            <span v-html="fileLabel2"></span>
          </label>
        </div>
      </div>

      <div class="l-form__item" v-if="showDateSelector">
        <label class="form-label">{{ $t('form_fields.validity_date') }}</label>

        <date-picker v-model="validityDate"
                     :settings="{
                      minDate: new Date()
                    }"
        ></date-picker>

        <d-form-invalid-feedback v-if="hasError('validityDate')" :force-show="true">
          <span v-html="getError('validityDate')"></span>
        </d-form-invalid-feedback>
      </div>

      <div v-if="hasError('others')" class="l-form__item">
        <d-form-invalid-feedback  :force-show="true">
          <span v-html="getError('others')"></span>
        </d-form-invalid-feedback>
      </div>

      <div class="l-form__item">
        <div v-if="uploading" class="sp-pl-8">
          <loading  />
        </div>
        <d-button v-else @click="uploadFile">{{ getId === 1 ? $t('on_boarding.file_upload.upload_file_opt') : $t('on_boarding.file_upload.upload_file') }}</d-button>
      </div>

    </div>
    <div v-else>
      <d-alert theme="success" :show="true">
        <strong>{{fileType}}:</strong> {{ $t('on_boarding.file_upload.file_uploaded') }}
      </d-alert>
    </div>

</template>

<script>

import mixins from "~/mixins";
import {rules} from "~/onBoardingSettings"
import DatePicker from "@/components/DatePicker";
import Loading from "@/components/loading";

export default {
  name: "FileUpload",
  components: {Loading, DatePicker},
  mixins: [mixins],

  props: {
    label: String,
    formName: String,
    fileType: {
      type: String,
      required: true
    },
    providerId: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      implemented: true,

      fileToUpload: null,
      fileToUpload_2: null,

      type: null,
      valid: false,
      menu: false,
      validityDate: null,
      showDateSelector: false,
      isUploaded: false,
      uploading: false,
      docID: false,
      errors: [],
    }
  },

  computed:{
    getId: function () {
      let id = 0
      if (this.type === "ID") id = 1
      if (this.type === "ChamberOfCommerce") id = 2
      if (this.type === "ArticlesOfAssociation") id = 3
      if (this.type === "AddressProof") id = 4
      if (this.type === "Bank") id = 5
      if (this.type === "TaxStatusCertificate") id = 6
      if (this.type === "RegistrationCertificate") id = 7
      return id
    },

    fileLabel: function () {
      if(this.isset(this.fileToUpload)) {
        return this.fileToUpload.name + '<small>(' + this.number_format(this.fileToUpload.size / 1000, 2, '.', '') + 'kB)</small>';
      }
      return this.$t('form_fields.search');
    },

    fileLabel2: function () {
      if(this.isset(this.fileToUpload_2)) {
        return this.fileToUpload_2.name + '<small>(' + this.number_format(this.fileToUpload_2.size / 1000, 2, '.', '') + 'kB)</small>';
      }
      return this.$t('form_fields.search');
    },
  },

  mounted() {
    if (this.fileType == 'ID') this.showDateSelector = true
    this.type = this.fileType
  },

  methods: {

    showExample() {
      console.log(`ID: ${this.getId}`)
      this.$emit('onShowExample', {id: this.getId})
    },

    hasError: function (field) {
      return this.errors.find(error => error.field === field);
    },

    getError: function (field) {
      return this.errors.find(error => error.field === field).messages.join('<br/>');
    },

    formatDate (date) {
      if (!date) return null

      if(typeof date === 'object') {
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
      }

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },

    previewFile (event) {
      if(event.target.files.length <= 0) return;
      this.fileToUpload = event.target.files[0];
    },

    previewFile2 (event) {
      if(event.target.files.length <= 0) return;
      this.fileToUpload_2 = event.target.files[0];
    },

    uploadFile () {

      this.errors = [];
      let valid = true;

      let rulesValid = this.runValidation(this.fileToUpload, rules.file.rules);
      if(!rulesValid[0]) {
        valid = false;
        this.errors.push({
          field: 'fileToUpload',
          messages: rulesValid[1]
        });
      }

      if(this.showDateSelector) {
        let rulesValid = this.runValidation(this.validityDate, rules.date.rules);
        if(!rulesValid[0]) {
          valid = false;
          this.errors.push({
            field: 'validityDate',
            messages: rulesValid[1]
          });
        }
      }

      if (!valid) {
        this.errors.push({
          field: 'others',
          messages: [this.$t('form_validation.contains_errors')]
        });
        return
      }

      this.uploading = true;

      const variables = {
        input: {
          providerID: this.providerId,
          type: this.type,
          file: this.fileToUpload,
        }
      }

      if(this.type == 'ID') {
        variables.validityDate = this.formatDate(this.validityDate)
        if(this.fileToUpload_2) variables.input.back = this.fileToUpload_2
      }

      this.$apollo.mutate({
        mutation: require('~/graphql/onBoarding/UploadDocument.gql'), variables, fetchPolicy: 'no-cache'
      }).then(data => {

        new Promise((resolve, reject) => {
          if(data.data.hipayUploadDocument.success) resolve();
          else reject(data.data.hipayUploadDocument);
        }).then(() => {
          this.uploadSucceeded()
        })
        .catch((error) => {

          this.uploading = false;
          console.error(error)
          this.errors.push({
            field: 'others',
            messages: [error.message]
          });
        })
      }).catch((error) => {
        this.uploading = false;
        console.error(error)
        this.errors.push({
          field: 'others',
          messages: [this.removeDefaultGraphQLPrefix(error.message)]
        });
      })
    },

    uploadSucceeded() {
      this.isUploaded = true;
      this.$emit("onUploaded", {type: this.type});
    }
  },

}
</script>
